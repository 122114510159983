.c-user-categories {
    margin: space(2) auto space(2);
    td {
        font-size: 12pt;
        padding: 0 space(2) space(1) 0;
        text-align: left;
    }
    tr td:last-child {
        padding-right: 0;
    }
    input {
        display: none;
    }
    label {
        cursor: pointer;
        display: inline-block;
        width: 120px;
    }
    span {
        display: block;
        padding: 5px 10px 5px 25px;
        border: 2px solid #ddd;
        border-radius: 5px;
        position: relative;
        transition: all 0.25s linear;
    }
    span::before {
        content: "";
        position: absolute;
        left: 5px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #ddd;
        transition: all 0.25s linear;
    }
    input:hover + span {
        border-color: palette("blue", 1);
    }
    input:checked + span {
        background-color: #fff;
        border-color: palette("blue", 1);
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
        color: palette("blue", 1);
    }
    input:checked + span::before {
        background-color: palette("blue", 1);
    }
    @include media(s) {
        td {
            font-size: 10pt;
        }
        label {
            width: 105px;
        }
    }
    &__close {
        position: absolute;
        right: space(2);
        top: space(2);
        transition: all 0.2s;
        z-index: 2;
        &.click-down svg {
            animation: spin-clockwise 0.2s ease-out;
            animation-fill-mode: forwards;
        }
        &.click-up svg {
            animation: spin-anti-clockwise 0.2s ease-in;
            animation-fill-mode: forwards;
        }
    }
}

.c-profile .c-user-categories {
    margin: space(2) 0 space(2);
    @include media(s) {
        tr td:first-child {
            padding-left: 0;
        }
        td {
            padding: 0 space(1) space(1) 0;
        }
        label {
            width: 102px;
        }
    }
}
