/* _component.comments.scss */

.c-comments {
    &.show {
        background-color: #ffffff;
        box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.15);
        border-radius: 16px 16px 0 0;
        display: block !important;
        position: fixed;
        top: 180px;
        left: 0;
        padding: 12px 24px calc(180px + 120px); // Calc is for top offset and sticky input
        overflow: auto;
        max-height: 100vh;
        z-index: 99;
    }
}

.c-comments__insert {
    align-items: flex-start;
    display: flex;
    margin-bottom: space(3.5);

    @include media(s) {
        box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 16px 16px 0 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 999;
        background-color: #ffffff;
        padding: 24px 16px 7px;
        width: 100%;
        left: 0;
        margin-bottom: 0;
    }
}

.c-comments__img {
    border: 1px solid #dddddd;
    border-radius: 100%;
    height: 40px;
    width: 40px;
}

.c-comments__box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: space(2);
}

.c-comments__input {
    @include rem(font-size, 14);
    background-color: #fcfcfc;
    border: 1px solid #eeeeee;
    border-radius: 12px;
    color: palette("black");
    font-weight: 600;
    min-height: 40px;
    overflow: hidden;
    padding: space(1) space(1.5);
    resize: none;

    &.activeTextarea {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &::placeholder {
        @include rem(font-size, 14px);
        color: #777777;
    }
}

.c-comments__bar {
    align-items: center;
    background-color: #fcfcfc;
    border-radius: 0 0 12px 12px;
    border: 1px solid #eeeeee;
    border-top: none;
    display: flex;
    justify-content: space-between;
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: space(1) space(1.5);
    transition: all 0.2s ease-in-out;

    &.activeUpload {
        height: 57px;
        opacity: 1;
    }

    button {
        @include rem(font-size, 14px);
        align-items: center;
        display: flex;
        height: 40px;
        padding: 0 space(3);

        p {
            margin-left: space(0.5);
        }
    }

    img {
        cursor: pointer;
    }
}

.c-comment__wrap {
    .replies_outer {
        //background-image: url("img/ui/icon-reply.svg");
        background-position: left space(2) top space(1);
        background-repeat: no-repeat;
        .replies_outer {
            background: none;
        }
        &.indent {
            padding-left: space(7);
        }
        .c-comments__user {
            p:first-child {
                &::after {
                    //content: url("img/ui/icon-user-reply.svg");
                    right: -15px;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }
}

.c-comments__comment {
    align-items: flex-start;
    display: flex;
    margin-bottom: space(4);

    &.replies {
        align-items: flex-end;
        display: flex;
        min-height: space(4);

        p {
            cursor: pointer;
        }
    }

    .c-comments__text {
        @include rem(font-size, 16);
        color: #000000;
        font-weight: 500;
        letter-spacing: -0.16px;
        line-height: 22px;
        white-space: pre-line;

        a {
            color: #47abc0;
        }
    }
}

.c-comments__content {
    margin-left: space(2);
    width: 100%;
}

.c-comments__user {
    display: flex;

    p {
        @include rem(font-size, 14px);
        color: #aaaaaa;
        font-weight: 600;
        line-height: 20px;
        margin-top: space(1.25);
        padding-left: space(1.5);
        position: relative;

        &:first-child {
            @include rem(font-size, 14);
            color: #777777;
            font-weight: 600;
            line-height: 20px;
            padding-left: 0;
        }
    }
}

.c-comments__cta {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .liked {
        path {
            fill: palette("red");
            stroke: palette("red");
        }
    }

    .like {
        cursor: pointer;

        &:hover {
            fill: palette("red");
        }
    }

    div {
        align-items: center;
        display: flex;
        padding-left: space(2.25);
    }

    p {
        @include rem(font-size, 14px);
        color: #777777;
        font-weight: 600;
        padding-left: space(0.75);
        position: relative;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            @include rem(font-size, 12);
            color: #000000;
        }

        //&:last-child {
        //    &::after {
        //        content: url("img/ui/icon-user-likes.svg");
        //        right: -10px;
        //        position: absolute;
        //        top: -1px;
        //    }
        //}
    }
}

.c-comments__photo {
    border: 1px solid #bfbfbf;
    border-top: none;
    padding: 0 space(1.5) space(3);
}

.comments__mobile {
    @include rem(font-size, 16);
    color: #000000;
    font-weight: 600;
    letter-spacing: -0.16px;
    line-height: 22px;
    margin-bottom: 48px;
    text-decoration: underline;
}

.typeahead {
    margin-top: 2px;
    z-index: 1051;
}

.dropdown-menu {
    background-clip: padding-box;
    background-color: #ffffff;
    border: 1px solid #bfbfbf;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    display: none;
    float: left;
    left: 0;
    list-style: none;
    margin: -1px 0;
    max-width: 275px;
    padding: space(1) space(1) space(2);
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;

    li {
        border-bottom: 1px solid #bfbfbf;
        padding: 8px;

        &:hover {
            background: rgba(32, 115, 146, 0.15);
            border-radius: 6px;
        }
    }

    a {
        @include rem(font-size, 14px);
        align-items: center;
        display: flex;
    }

    .mention_image {
        border: 1px solid palette("border");
        border-radius: 100%;
        margin-right: space(1);
        max-width: 24px;
    }

    .mention_username {
        color: palette("grey", 2);
        margin-left: space(1);

        strong {
            font-weight: 400;
        }
    }
}
