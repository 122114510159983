.c-empty-state {
    h4 {
        @include rem("font-size", 16);
        font-weight: 600;
    }
    > p {
        @include rem("font-size", 14);
        color: palette("grey", 2);
        margin-bottom: space(1);
        margin-top: space(1);
    }

    .c-empty-state__community_select {
        display: flex;

        .c-communities-select__tile {
            display: flex;
            flex-direction: column;
            max-width: 106px;

            &:hover {
                cursor: pointer;
            }

            @include media(s) {
                &::after {
                    width: calc(100% + 320px);
                }
            }

            .c-communities-select__img {
                border: 1px solid palette("grey", 2);
                border-radius: 5px;

                @include media(m) {
                    padding: space(2);
                }
            }

            .c-communities-select__name {
                p {
                    @include rem("font-size", 12);
                    margin-top: space(2);
                    color: palette("black");
                }
            }
        }
    }
}
