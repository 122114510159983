//
// Global colour palette
// stylelint-disable value-list-max-empty-lines
$palettes: (
	"deep-black": (
        1: #000
    ),

    "off-white": (
        1: #f3f3f3
    ),

    "black": (
		1: #222,
		2: #000,
		3: #979797
	),

	"blue": (
		1: #47ABC0,
		2: #3A5897,
		3: #02164A,
		4: #6178AA
	),

	"red-dark": (
		1: #670000
	),

	"red": (
		1: #DB2848,
		2: #F16D6F,
		3: #BF3234
	),

	"teal": (
		1: #3FC3D2
	),

	"grey": (
		1: #444,
		2: #888,
		3: #d8d8d8,
		4: #ebebeb,
		5: #dedede,
		6: #8c8c8c,
        7: #f3f3f3,
        8: #aaaaaa,
        9: #777777,
        10: #eeeeee
	),

	"border": (
		1: #979797
	),

	"text":(
		1: #222,
	),

	"white": (
		1: #fdfdfd,
		2: #F7F7F7
	),

    "brilliant-white": (
        1: #FFFFFF
    ),

	"divider":(
		1: #D8D8D8
	),

);
