.card-message {
    background-color: palette("white");
    border: 1px solid palette("grey", 3);
    bottom: 42px;
    box-shadow: 0 0 6px 0 rgba(50, 50, 50, 0.20);
    color: palette("black");
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: left;
    z-index: 2;

    &::after {
        background: url("img/ui/menu-arrow.png") no-repeat center;
        background-size: 26px;
        bottom: -20px;
        content: "";
        height: 26px;
        position: absolute;
        right: 24px;
        transform: rotate(180deg);
        width: 20px;
        z-index: -1;
    }

    &.left {
        left: 26px;
        right: auto;
    }

    &.left::after {
        right: auto;
    }

    &.show {
        height: auto;
        min-width: 280px;
        opacity: 1;
        overflow: visible;
        padding: space(3);
        width: 100%;
    }

    div {
        align-items: center;
        display: flex;
        margin-bottom: space(2);

        &:hover {
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
    }

    img {
        height: 20px;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 20px;
    }

    h5 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0 !important;
        margin-left: space(0.5);
        margin-top: space(1.5);
    }

    p {
        font-size: 14px;
        font-weight: 400;
        padding: 4px;
    }

    a {
        color: palette("blue");
        font-weight: bold;
        text-decoration: underline;
    }
}
