.c-ugc {
    .hero-upload {
        position: static;
        height: 350px;
    }
    .hero-wrapper {
        position: absolute;
        height: 350px;
        left: 0;
        margin: 0 auto;
        right: 0;
        width: 650px;

        @include media(m) {
            position: relative;
        }
    }
    .hero-upload label {
        display: block;
        .hero-box {
            align-items: center;
            border: 2px dashed #aaa;
            border-radius: 16px;
            display: flex;
            height: 350px;
            overflow: hidden;
            position: relative;
            width: 650px;
            img {
                width: 100%;
            }
            .remove {
                background-color: #fff;
                border: 1px solid #aaa;
                opacity: 0.5;
                position: absolute;
                right: space(0.5);
                top: space(0.5);
                img {
                    padding: space(0.5);
                }
            }
            .uploading {
                background-color: #fff;
                border: 1px solid #eee;
                border-radius: 5px;
                bottom: 5px;
                font-size: 12px;
                height: 36px;
                opacity: 0.5;
                position: absolute;
                right: 5px;
                width: 108px;
                .content {
                    padding-left: 5px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .upload-area {
        align-items: center;
        border: 2px dashed #aaa;
        border-radius: 16px;
        color: #777;
        display: flex;
        flex-direction: column;
        height: 350px;
        justify-content: center;
        img {
            margin-bottom: space(0.5);
        }
        p {
            font-size: 20px;
            font-weight: 500;
            line-height: 25px;
        }
    }
    .hero-buttons {
        display: flex;
        justify-content: flex-end;
        .c-btn {
            margin: space(1);
            padding: 8px space(4);
        }
    }
    @include media(m) {
        .hero-wrapper {
            width: 100%;
        }
        .hero-upload label {
            .hero-box {
                position: relative;
                width: 100%;
            }
        }
        .upload-area {
            img {
                margin: 0 space(2);
            }
        }
    }
    .editor-content {
        margin: 0 auto;
        position: static;
        width: 634px;
    }
    figure.image {
        display: block;
        img {
            width: 100%;
        }
        &.image-style-align-center {
            display: table;
            width: 75%;
            img {
                width: auto;
            }
        }
        &.image-style-align-left {
            float: left;
            width: 50%;
            img {
                width: auto;
            }
        }
        &.image-style-align-right {
            float: right;
            width: 50%;
            img {
                width: auto;
            }
        }
    }

    .ck-placeholder {
        &:before {
            color: #777;
            font-size: 20px;
            font-weight: 500;
            line-height: 25px;

            @include media(m) {
                left: 48px !important;
            }

            @include media(s) {
                font-size: 16px !important;
            }
        }
    }

    .ck-content {
        margin-bottom: space(6);
        word-break: break-word;
        word-wrap: break-word;
        p {
            color: #000;
            font-size: 17px;
            font-weight: 500;
            line-height: 29px;
            margin-top: space(4);

            &:first-child {
                margin-top: 0;
            }

            strong {
                font-weight: 700;
            }
        }

        @include media(m) {
            margin-bottom: space(4);
        }

        a {
            color: palette("blue");
        }

        .image > figcaption {
            display: block;
        }
    }
    .contribute-indicator {
        border: 1px solid #777;
        color: #777;
        border-radius: 80px;
        display: block;
        font-size: 40px;
        line-height: 40px;
        position: relative;
        height: 44px;
        left: -50px;
        text-align: center;
        top: 34px;
        visibility: hidden;
        width: 44px;
        &.active {
            visibility: visible;
        }
        @include media(m) {
            left: 0;
        }
    }
}

.ck.ck-toolbar {
    background: #f3f3f3 !important;
    border-radius: 57px !important;
    padding: 2px 12px !important;
}

.ck.ck-balloon-panel {
    border: none !important;
    border-radius: 57px !important;
    box-shadow: none !important;
}
